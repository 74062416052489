import { Box, Container, Typography } from "@mui/material";
import React from "react";

import image from "../../assets/LandingPage/electric-building.png";
import testImage from "../../assets/LandingPage/electric-building-text.svg";

function ElectricBuilding() {
  return (
    <>
      <Box
        sx={{
          position: "relative",
          color: "#fff",
          width: "100%",
          height: "130vh",
          display: "flex",
        }}
      >
        <Box
          component="img"
          alt="Image"
          src={image}
          sx={{
            height: "130vh",
            position: "absolute",
            width: "100%",
          }}
          loading="lazy"
        />

        <Container
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: "column",
              padding: "1rem 0 0 0",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "2rem", md: "2.4rem" },
                lineHeight: { xs: "30px", md: "35px" },
                fontWeight: 250,
                fontFamily: "CustomFont, sans-serif",
              }}
            >
              The Future is <span style={{ fontWeight: "bold" }}>ELECTRIC</span>
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "2rem", md: "2.4rem" },
                lineHeight: { xs: "30px", md: "35px" },
                fontWeight: 250,
                fontFamily: "CustomFont, sans-serif",
              }}
            >
              and we are <span style={{ fontWeight: "bold" }}>BUILDING</span> it
            </Typography>
          </Box>
          <Box
            component="img"
            alt="Image"
            src={testImage}
            sx={{
              position: "relative",
              width: { xs: "70%", md: "50%" },
            }}
            loading="lazy"
          />
        </Container>
      </Box>

      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",

        }}
      >
        <Box sx={{ backgroundColor: "#fff", py: "20px", textAlign: "center"
          
         }}>
          <Typography
            sx={{
              fontSize: { xs: "1rem", md: "2.1rem" },
              lineHeight: { xs: "18px", md: "20px" },
              fontWeight: "bold",
              fontFamily: "CustomFont, sans-serif",
              color: "#000",
            }}
          >
            Registered under the Sheikh Mohammed bin Rashid
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{ py: "3px", backgroundColor: "#FF0000", width: "100%" }}
          ></Box>
          <Box
            sx={{ py: "3px", backgroundColor: "#06662D", width: "100%" }}
          ></Box>
          <Box
            sx={{ py: "3px", backgroundColor: "#BFBFBF", width: "100%" }}
          ></Box>
          <Box
            sx={{ py: "3px", backgroundColor: "#010101", width: "100%" }}
          ></Box>
        </Box>
      </Box> */}
    </>
  );
}

export default ElectricBuilding;
