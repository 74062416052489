import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

import image from "../../assets/logo.svg";
import moment from "moment";

function Footer() {
  const year = moment().year();

  return (
    <>
      <Box sx={{ borderBottom: "2px solid rgb(12, 243, 154)" }}>
        <Container>
          <Box
            sx={{
              padding: { xs: "1.5rem", md: "2rem" },
            }}
          >
            <Grid container spacing={8}>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "center", md: "left" },
                }}
              >
                <Box
                  component="img"
                  alt="Image"
                  src={image}
                  sx={{
                    width: { xs: "100%", md: 150 },
                    paddingTop: { xs: "2rem", md: "0" },
                    maxWidth: 150,
                  }}
                  loading="lazy"
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "end" },
                  alignItems: { xs: "center", md: "end" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: { xs: 3, md: 5 },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "1rem", md: "1.1rem" },
                      lineHeight: { xs: "18px", md: "20px" },
                      fontWeight: 350,
                      fontFamily: "CustomFont, sans-serif",
                    }}
                  >
                    Home
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "1rem", md: "1.1rem" },
                      lineHeight: { xs: "18px", md: "20px" },
                      fontWeight: 350,
                      fontFamily: "CustomFont, sans-serif",
                    }}
                  >
                    Service
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "1rem", md: "1.1rem" },
                      lineHeight: { xs: "18px", md: "20px" },
                      fontWeight: 350,
                      fontFamily: "CustomFont, sans-serif",
                    }}
                  >
                    About
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "1rem", md: "1.1rem" },
                      lineHeight: { xs: "18px", md: "20px" },
                      fontWeight: 350,
                      fontFamily: "CustomFont, sans-serif",
                    }}
                  >
                    Contact
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      <Container>
        <Box
          sx={{
            padding: { xs: "1.5rem", md: "1rem" },
          }}
        >
          <Grid container spacing={8}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", md: "left" },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "1rem", md: "1.1rem" },
                  lineHeight: { xs: "18px", md: "20px" },
                  fontWeight: 350,
                  fontFamily: "CustomFont, sans-serif",
                }}
              >
                Copyright © {year} Voltup
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "end" },
                alignItems: { xs: "center", md: "end" },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "1rem", md: "1.1rem" },
                  lineHeight: { xs: "18px", md: "20px" },
                  fontWeight: 350,
                  fontFamily: "CustomFont, sans-serif",
                }}
              >
                info@voltup.ai | +971 585604833
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default Footer;
