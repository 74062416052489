import React, { useState } from "react";
import OnDemandCharging from "../components/LandingPage/OnDemandCharging";
import AtStationCharging from "../components/LandingPage/AtStationCharging";
import WhyVoultUp from "../components/LandingPage/WhyVoultUp";
import { Box, Container } from "@mui/material";
import PowerUpJourney from "../components/LandingPage/PowerUpJourney";
import Footer from "../components/Footer/Footer";
import ElectricBuilding from "../components/LandingPage/ElectricBuilding";

import Logo from "../assets/logo.svg";
import Hero from "../components/LandingPage/Hero";

function LandingPage() {
  // const [isLoading, setIsLoading] = useState(true);

  // const handleImageLoad = () => {
  //   setIsLoading(false);
  // };

  return (
    <>
      {/* {isLoading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#000",
            zIndex: 9999,
          }}
        >
          <Box
            component="img"
            alt="VoultUp"
            src={Logo}
            sx={{
              width: "500px",
              height: "500px",
            }}
            loading="lazy"
          />
        </Box>
      )} */}

      {/* <Hero handleImageLoad={handleImageLoad} /> */}
      <Hero />

      <Container>
        <OnDemandCharging />

        <AtStationCharging />
      </Container>

      <WhyVoultUp />

      <ElectricBuilding />

      <Container>
        <PowerUpJourney />
      </Container>

      <Box
        sx={{
          margin: { xs: "1rem 0", md: "2rem 0" },
        }}
      >
        <Footer />
      </Box>
    </>
  );
}

export default LandingPage;
