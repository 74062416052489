import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";

import image from "../../assets/LandingPage/on-demand.svg";

import NorthEastIcon from "@mui/icons-material/NorthEast";

function OnDemandCharging() {
  return (
    <Box
      sx={{
        padding: { xs: "2rem 1rem", md: "4rem 2rem" },
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: "1rem", md: "1.2rem" },
          lineHeight: { xs: "20px", md: "40px" },
          fontWeight: "bold",
          fontFamily: "CustomFont, sans-serif",
          display: { xs: "block", md: "none" },
        }}
      >
        Choose Your{" "}
        <span
          style={{
            color: "#02D29E",
          }}
        >
          Charging Experience
        </span>
      </Typography>

      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            order: { xs: 1, md: 0 },
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ fontFamily: "CustomFont, sans-serif" }}>
            <Typography
              sx={{
                fontSize: { xs: "1rem", md: "1.2rem" },
                lineHeight: { xs: "20px", md: "40px" },
                fontWeight: "bold",
                fontFamily: "CustomFont, sans-serif",
                display: { xs: "none", md: "block" },
              }}
            >
              Choose Your{" "}
              <span
                style={{
                  color: "#02D29E",
                }}
              >
                Charging Experience
              </span>
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "3.2rem", md: "5.2rem" },
                lineHeight: { xs: "45px", md: "90px" },
                fontWeight: 250,
                fontFamily: "CustomFont, sans-serif",
                paddingY: { xs: "20px", md: "25px" },
              }}
            >
              On-Demand Charging
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "1rem", md: "1.1rem" },
                lineHeight: { xs: "18px", md: "20px" },
                fontWeight: 350,
                opacity: "60%",
                fontFamily: "CustomFont, sans-serif",
              }}
            >
              Request a charging service at your location, on your schedule.
            </Typography>
            <Button
              variant="outlined"
              sx={{
                mt: 2,
                color: "#fff",
                fontFamily: "CustomFont, sans-serif",
                borderRadius: 0,
                borderColor: "rgba(255, 255, 255, 0.5)",
                textTransform: "none",
              }}
            >
              Request service{" "}
              <NorthEastIcon
                sx={{
                  ml: 1,
                  fontSize: "18px",
                  color: "#02D29E",
                }}
              />
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            order: { xs: 0, md: 1 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            alt="Image"
            src={image}
            sx={{
              width: { xs: "100%", md: 400 },
              paddingTop: { xs: "2rem", md: "0" },

              maxWidth: 400,
            }}
            loading="lazy"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default OnDemandCharging;
