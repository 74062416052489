import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import image from "../../assets/LandingPage/power-up.svg";
import qr from "../../assets/LandingPage/power-up-qr.svg";

function PowerUpJourney() {
  return (
    <Box
      sx={{
        padding: { xs: "6rem 1rem", md: "8rem 2rem" },
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            alt="Image"
            src={image}
            sx={{
              width: { xs: "100%", md: 450 },
              paddingTop: { xs: "2rem", md: "0" },
              maxWidth: 450,
            }}
            loading="lazy"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: 3,
          }}
        >
          <Box
            component="img"
            alt="Image"
            src={qr}
            sx={{
              width: { xs: "100%", md: 150 },
              paddingTop: { xs: "2rem", md: "0" },
              maxWidth: 150,
            }}
            loading="lazy"
          />
          <Typography
            sx={{
              fontSize: { xs: "1.5rem", md: "2.1rem" },
              lineHeight: { xs: "25px", md: "40px" },
              fontWeight: 350,
              fontFamily: "CustomFont, sans-serif",
              width: "80%",
            }}
          >
            PowerUp Your Journey with VoltUp
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PowerUpJourney;
