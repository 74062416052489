import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import "./WhyVoultUp.css";

// import image from "../../assets/LandingPage/why-voultup.svg";
import image from "../../assets/LandingPage/plug.png";
import vector1 from "../../assets/LandingPage/Vector1.png";
import vector2 from "../../assets/LandingPage/Vector2.png";
import vector3 from "../../assets/LandingPage/Vector3.png";
import vector4 from "../../assets/LandingPage/Vector4.png";
import { useInView } from "react-intersection-observer";

function WhyVoultUp() {
  const [scrollProgress, setScrollProgress] = useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { ref, inView, entry } = useInView({ threshold: 0 });
  const handleScroll = () => {
    if (!inView || !entry) return;

    const { top, height } = entry.target.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const scrollY = window.scrollY || window.pageYOffset;
    const componentTop = top + scrollY;
    const windowBottom = scrollY + windowHeight;

    const visibleHeight = Math.min(
      height,
      Math.max(0, windowBottom - componentTop)
    );
    const scrollPercent = visibleHeight / height;
    const scrollMinusPercentage = scrollPercent;

    setScrollProgress(scrollMinusPercentage);
  };

  useEffect(() => {
    const handleScrollEvent = () => handleScroll();
    window.addEventListener("scroll", handleScrollEvent);

    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, [inView, entry]);
  const pathRef = useRef<any>(null);
  const [pathLength, setPathLength] = useState(0);

  // Calculate path length on mount
  useEffect(() => {
    if (!pathRef.current?.getTotalLength) return;
    if (pathRef.current) {
      setPathLength(pathRef.current.getTotalLength());
    }
  }, []);
  return (
    <Box
      sx={{
        padding: { xs: "2rem 1rem 0 1rem", md: "4rem 2rem 0 2rem" },
        position: "relative",
        backgroundColor: "#02D29E",
      }}
    >
      <div className="custom-shape-divider-top-1733937969">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>

      <Typography
        sx={{
          fontSize: { xs: "2.2rem", md: "4.2rem" },
          lineHeight: { xs: "45px", md: "90px" },
          fontWeight: 250,
          fontFamily: "CustomFont, sans-serif",
          textAlign: "center",
          position: "relative",
          mt: { xs: "2rem 1rem", md: "4rem 2rem" },
        }}
      >
        Why{" "}
        <span
          style={{
            color: "#02D29E",
            fontWeight: "bold",
          }}
        >
          Voltup
        </span>
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          alignItems: "center",
        }}
      >
        {/* section 1 */}
        <Stack
          sx={{
            alignItems: "flex-end",
            mt: 17,
            mr: -0.5,
            display: { xs: "none", md: "inherit" },
          }}
        >
          <Box
            component={"img"}
            alt="Image"
            src={vector1}
            sx={{
              width: { xs: "100%", md: 180 },
              maxWidth: 180,
            }}
          />
          <Stack
            sx={{
              pr: { md: 0, lg: 11, xl: 15.5 },
              pl: { xs: 1, lg: 0 },
              mt: 1.5,
              alignItems: "flex-start",
            }}
          >
            <Typography variant="h4" fontWeight="bold">
              On-Demand Charging
            </Typography>
            <Typography
              variant="subtitle1"
              mt={1.5}
              sx={{ fontWeight: 400, color: "floralwhite" }}
            >
              Request a charging truck to your exact <br /> location - whether
              you’re at home, on the <br /> road, or anywhere in between.
            </Typography>
          </Stack>
        </Stack>
        <Box
          component="img"
          alt="Image"
          src={image}
          sx={{
            width: { xs: 220, md: 300 },
            marginTop: { xs: "1rem", md: "-1rem" },
            maxWidth: 300,
            overflowX: "hidden",
            zIndex: 1,
          }}
          loading="lazy"
        />
        {/* section 3 */}
        <Stack sx={{ mt: 3, ml: -0.5, display: { xs: "none", md: "inherit" } }}>
          <Box
            component={"img"}
            alt="Image"
            src={vector3}
            sx={{
              width: { xs: "100%", md: 180 },
              maxWidth: 180,
            }}
          />
          <Typography
            variant="h4"
            fontWeight="bold"
            ml={{ xl: 10, lg: 10 }}
            mt={1}
          >
            24/7 Support
            <Typography
              variant="subtitle1"
              mt={1.5}
              sx={{ fontWeight: 400, color: "floralwhite" }}
            >
              Our team is available around the clock for an
              <br />
              troubleshooting. And NO, we don't use bots!
            </Typography>
          </Typography>
        </Stack>
      </Box>
      <Box
        ref={ref}
        // position="relative"
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: { xs: -4, lg: -4, xl: -2 },
          mx: { xs: -2, md: -4 },
          ml: { xs: -6, sm: -6, xl: 2 },
          pb: { xs: 10, md: 15 },
        }}
      >
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="865"
          height="472"
          // viewBox="0 0 766 472"
          fill="none"
        ></svg> */}
        <Box
          sx={{ width: "865px", display: "flex", justifyContent: "flex-end" }}
        >
          <Stack
            sx={{
              display: { xs: "none", md: "inherit" },
              alignItems: "flex-end",
            }}
          >
            <Box
              component={"img"}
              alt="Image"
              src={vector2}
              sx={{
                width: { xs: "100%", md: 180 },
                maxWidth: 180,
              }}
            />
            <Stack
              sx={{
                // pl: { xs: -10 },
                // mt: 1.5,
                pr: 17,
                // alignItems: "flex-start",
              }}
            >
              <Typography variant="h4" fontWeight="bold">
                Comprehensive & <br /> easy-to-use software
              </Typography>
              <Typography
                variant="subtitle1"
                mt={1.5}
                sx={{ fontWeight: 400, color: "floralwhite" }}
              >
                Easily locate stations, request charging, track <br /> your
                usage, and manage your sessions - all <br /> from a single app.
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ overflowX: "hidden", position: "relative" }}>
          <svg width="900" height="472" fill="none">
            <path
              d="M14.5002 0.999576C29.0002 216.5 311 509.5 900 450"
              stroke="#000000"
              strokeWidth={matches ? 14 : 28}
              pathLength="1347.42"
            />
            <path
              ref={pathRef}
              d="M14.5002 0.999576C29.0002 216.5 311 509.5 900 450"
              stroke="#007bff"
              strokeWidth={matches ? 4 : 8}
              strokeDasharray={pathLength}
              strokeDashoffset={
                scrollProgress > 0.999
                  ? pathLength - scrollProgress * pathLength
                  : pathLength - scrollProgress * pathLength + 250
              }
              // style={{ transition: "stroke-dashoffset 0.1s ease-out" }}
            />
          </svg>

          <Stack
            sx={{
              mt: 3,
              ml: 4,
              display: { xs: "none", md: "inherit" },
              position: "absolute",
              top: 0,
            }}
          >
            <Box
              component={"img"}
              alt="Image"
              src={vector4}
              sx={{
                width: { xs: "100%", md: 200 },
                maxWidth: 200,
              }}
            />
            <Typography
              variant="h4"
              fontWeight="bold"
              ml={{ xl: 20, xs: 20 }}
              mt={1}
            >
              Fast Charging
              <Typography
                variant="subtitle1"
                mt={1.5}
                sx={{ fontWeight: 400, color: "floralwhite" }}
              >
                Our team is available around the clock for an
                <br />
                troubleshooting. And NO, we don't use bots!
              </Typography>
            </Typography>
          </Stack>
        </Box>
        {/* <Stack
          sx={{
            position: "absolute",
            // transform: "translate(-50%, -50%)",
            right: "140px",
            top: "50%",

            display: { xs: "none", md: "inherit" },
          }}
        >
          <Box
            component={"img"}
            alt="Image"
            src={vector4}
            sx={{
              width: { xs: "100%", md: 180 },
              maxWidth: 180,
            }}
          />
          <Stack
            sx={{
              alignItems: "flex-start",
              ml: 15,
            }}
          >
            <Typography variant="h4" fontWeight="bold">
              Fast Charging
            </Typography>
          </Stack>
        </Stack> */}
      </Box>
      <Stack
        sx={{
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          gap: 3,
          alignItems: "center",
          mt: -18.5,
          pb: 5,
        }}
      >
        <Stack width={{ xs: 350, sm: 450 }}>
          <Typography variant="h4" fontWeight="bold">
            • On-Demand Charging
          </Typography>
          <Typography
            variant="subtitle1"
            mt={1.5}
            sx={{ fontWeight: 400, color: "floralwhite" }}
          >
            Request a charging truck to your exact location - whether you’re at
            home, on the road, or anywhere in between.
          </Typography>
        </Stack>
        <Stack width={{ xs: 350, sm: 450 }}>
          <Typography variant="h4" fontWeight="bold">
            • Comprehensive & easy-to-use software
            <Typography
              variant="subtitle1"
              mt={1.5}
              sx={{ fontWeight: 400, color: "floralwhite" }}
            >
              Easily locate stations, request charging, track your usage, and
              manage your sessions - all from a single app.
            </Typography>
          </Typography>
        </Stack>
        <Stack width={{ xs: 350, sm: 450 }}>
          <Typography variant="h4" fontWeight="bold">
            • 24/7 Support
            <Typography
              variant="subtitle1"
              mt={1.5}
              sx={{ fontWeight: 400, color: "floralwhite" }}
            >
              Easily locate stations, request charging, track your usage, and
              manage your sessions - all from a single app.
            </Typography>
          </Typography>
        </Stack>
        <Stack width={{ xs: 350, sm: 450 }}>
          <Typography variant="h4" fontWeight="bold">
            • Fast Charging
            <Typography
              variant="subtitle1"
              mt={1.5}
              sx={{ fontWeight: 400, color: "floralwhite" }}
            >
              Easily locate stations, request charging, track your usage, and
              manage your sessions - all from a single app.
            </Typography>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

export default WhyVoultUp;
